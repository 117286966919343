<template>
  <section class="about-us-section">
    <div class="static-page">
      <div class="header with-image">
        <img
          loading="lazy"
          src="@/assets/img/auth-shape-top.svg"
          alt="shape of dot"
          class="header-shape-top"
        />
        <h1 class="header-title">Our Story</h1>
        <div class="container">
          <div class="col-sm-12">
            <div class="image-container">
              <img
                loading="lazy"
                src="@/assets/img/imac.webp"
                alt="show vgang site on desktop screen"
                class="header-image"
              />
              <img
                loading="lazy"
                src="@/assets/img/static-page-shape.svg"
                alt="shape"
                class="shape"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="container">
          <div class="col-sm-12">
            <p class="staticpage-paragraph">
              VGANG is a digital warehouse platform that connects green
              manufacturers & brands to online retailers and increases their
              distribution into specialty stores around EU, UK and North
              America.
            </p>
            <p class="staticpage-paragraph">
              We have heard a lot about climate change, the problems of
              industrial products, the immoral use of animal products, and much
              more. We may even be affected, turn vegan, vegetarian or change
              ours to more planet-friendly. However, with all the growth of the
              vegan community in recent years - thanks to the Internet! - still,
              it’s not that easy to follow our decision.
            </p>
            <p class="staticpage-paragraph">
              Most of us, around the world, are struggling to find what we need
              for our daily base life. many vegan and vegetarian products are
              scarce in many cities and countries, and even if they exist,
              access to them is not as easy as other kinds of products.
            </p>
            <p class="staticpage-paragraph">
              We as VGANG want to increase easy access to green products for
              people around the world. Super ambitious, huh? Of course, we can’t
              do it by ourselves, but we believe in power is in unity. So, what
              if all of us, who have an Instagram account, Facebook page, or
              online shops, start selling green products to conscious friends
              among us? Yes! power is in unity. All of us together can have a
              huge impact on saving the planet of earth and ending the cruelty
              to animals.
            </p>
            <p class="staticpage-paragraph">
              We are not supposed to turn all the people to vegan or vegetarian,
              but we want to try to spread this culture and lifestyle as much as
              possible and help people make more informed decisions about their
              way of life with a greater awareness of this "worldview".
            </p>
            <p class="staticpage-paragraph">
              And, we as VGANG are committed to bridging this gap as much as
              possible by gathering green products suppliers and connecting them
              to whoever is in this mission with us to increase the
              accessibility to green and sustainable products for all people
              around the world.
            </p>
            <p class="staticpage-paragraph italic">
              Whisper: You will make a lot of money
            </p>
            <p class="staticpage-paragraph">
              It is true that our motivation to enter this field is the values
              of attachment to veganism. But get the truth, considering that
              this market is very small and the big players haven't seriously
              entered it yet, it's a very good opportunity to starting a
              business or adding a new line to your current one.
            </p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="staticpage-card">
                <span class="card-title">VGANG</span>
                <h2 class="card-label">For Suppliers</h2>
                <buttonLink
                  :isInternal="true"
                  :content="'Apply to be a Supplier Now!'"
                  :destination="'/register/supplier'"
                  :longButton="true"
                >
                </buttonLink>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="staticpage-card">
                <span class="card-title">VGANG</span>
                <h2 class="card-label">For Dropshippers</h2>
                <buttonLink
                  :isInternal="true"
                  :content="'Apply to be a dropshipper Now!'"
                  :destination="'/register/retailer'"
                  :longButton="true"
                >
                </buttonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import buttonLink from "@/components/bases/ButtonLink.vue";
export default {
  name: "AboutUs",
  components: {
    buttonLink,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          vmid: "description",
          content:
            "We as VGANG want to increase easy access to green products for people around t possible by gathering green products suppliers and connecting themhe world And we as VGANG are committed to bridging this gap as much as  to whoever is in this mission with us ",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.about-us-section {
  margin-top: 155px;
}
</style>
